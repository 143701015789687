import { useRouter } from 'next/router'
import { ReactElement, useEffect, useState } from 'react'
import useSubscriptionPlan from '~/components/Subscription/useSubscriptionPlan'
import configuration from '~/configuration'
import { NODE_ENV, PUBLIC_APP_URL } from '~/core/constants/env'

const withClientHandleCustomDomainMiddleware = <
  T extends { pageProps: object }
>(
  Component: (props: any) => ReactElement
) => {
  const WithClientHandleCustomDomainMiddleware = <F extends T>(props: F) => {
    const router = useRouter()
    const [suspend, setSuspend] = useState(true)

    const { data } = useSubscriptionPlan()
    useEffect(() => {
      if (typeof window !== 'undefined' && router.isReady) {
        const isCustomDomain =
          NODE_ENV === 'development'
            ? !['localhost:3000'].includes(window.location.host)
            : !PUBLIC_APP_URL?.includes(window.location.host)

        if (
          isCustomDomain &&
          !router.pathname.startsWith(configuration.path.career.list) &&
          !router.pathname.startsWith(configuration.path.error404)
        ) {
          router.push(configuration.path.career.list)
        } else {
          setSuspend(false)
        }
      }
    }, [router, data])

    return suspend ? <></> : <Component {...props} />
  }
  return WithClientHandleCustomDomainMiddleware
}
export default withClientHandleCustomDomainMiddleware
