import { parseCookies } from 'nookies'
import { useMemo } from 'react'
import { createClient, fetchExchange } from 'urql'
import configuration from '~/configuration'
import {
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_IP,
  SESSION_COOKIE_NAME
} from '../constants/cookies'
import { AGENCY_TENANT } from '../constants/enum'
import { DEFAULT_LOCALE, NODE_ENV, PUBLIC_APP_URL } from '../constants/env'

/**
 * @name useGraphQLRequest
 * @description A hook to make queries language
 */
export function useGraphQLRequest<Resp = unknown, Body = void>({
  language,
  isCareerHub
}: {
  language?: string
  isCareerHub?: boolean
}) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const cookies = parseCookies()
  const authenticationToken = cookies[SESSION_COOKIE_NAME]
  const ip = cookies[SESSION_COOKIE_IP]
  const currentTenantCookie = cookies[SESSION_COOKIE_CURRENT_TENANT]
  const currentTenantObj = currentTenantCookie
    ? JSON.parse(currentTenantCookie)
    : {}
  const isCustomDomain =
    typeof window !== 'undefined'
      ? NODE_ENV === 'development'
        ? !['localhost:3000'].includes(window.location.host as string)
        : !PUBLIC_APP_URL?.includes(window.location.host as string)
      : false
  const detectUrl = isCustomDomain
    ? isCareerHub
      ? configuration.api.customDomain.chubGraphQL
      : currentTenantObj?.companyKind === AGENCY_TENANT
      ? configuration.api.customDomain.agencyGraphQL
      : configuration.api.customDomain.graphQL
    : isCareerHub
    ? configuration.api.chubGraphQL
    : currentTenantObj?.companyKind === AGENCY_TENANT
    ? configuration.api.agencyGraphQL
    : configuration.api.graphQL

  const fetchTimeout = async (
    input: RequestInfo | URL,
    init?: RequestInit | undefined
  ) => {
    const controller = new AbortController()

    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) return

      if (event.data.type === 'ABORT_REQUEST') {
        controller.abort()
      }

      return
    }

    window.addEventListener('message', handleMessage)

    return fetch(input, { ...init, signal: controller.signal })
  }

  const client = useMemo(
    () =>
      createClient({
        url: detectUrl,
        exchanges: [fetchExchange],
        fetch: fetchTimeout,
        fetchOptions: () => {
          return {
            headers: {
              authorization: `Bearer ${authenticationToken}`,
              language: language || DEFAULT_LOCALE,
              timezone: timeZone,
              IP: String(ip),
              ...(isCustomDomain && typeof window !== 'undefined'
                ? {
                    'Access-Control-Allow-Origin': '*',
                    customDomain: `${window.location.host}/career`
                  }
                : undefined)
            }
          }
        }
      }),
    [
      currentTenantObj?.companyKind,
      authenticationToken,
      language,
      ip,
      isCareerHub,
      isCustomDomain
    ]
  )

  return client
}
