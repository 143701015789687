import { isServer } from '@tanstack/react-query'
import { Client } from '@urql/core'
import { addMinutes } from 'date-fns'
import { Router, useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import pathConfiguration from 'src/configuration/path'
import { AnyVariables, DocumentInput } from 'urql'
import { undefined } from 'zod'
import { create } from 'zustand'
import { NODE_ENV, PUBLIC_APP_URL } from '~/core/constants/env'
import { useGraphQLRequest } from '~/core/hooks/use-graphQL'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { debounce } from '~/core/ui/utils'
import { LIST_KEYS_TENANT_EXPIRED } from '~/core/utilities/catch-api-error'
import { TENANT_PLANS_STATUS } from '~/lib/features/settings/plans/utilities/enum'
import QueryTenantPlan from '~/lib/graphql/query-tenant-plan'
import useBoundStore from '~/lib/store'
import { FeatureName } from './subscription'
type ErrorHandleMap = { [key: string]: () => void }
const catchExt = (graphQLErrors: object[], errorHandleMap: ErrorHandleMap) => {
  const parseErrors = JSON.parse(
    JSON.stringify((graphQLErrors?.[0] || {}) as object)
  )
  const keys =
    parseErrors?.message && typeof parseErrors?.message === 'string'
      ? parseErrors?.extensions?.errors
      : parseErrors?.message?.extensions?.errors
  const statusCode =
    parseErrors?.message && typeof parseErrors?.message === 'string'
      ? parseErrors?.extensions?.http_code
      : parseErrors?.message?.extensions?.http_code

  let blockedErrorCode =
    parseErrors?.extensions?.error_code ||
    parseErrors?.message?.extensions?.error_code
  if (
    blockedErrorCode &&
    LIST_KEYS_TENANT_EXPIRED.includes(blockedErrorCode) &&
    [401, 403].includes(statusCode)
  ) {
    if (errorHandleMap) {
      Object.keys(errorHandleMap).forEach((key) => {
        LIST_KEYS_TENANT_EXPIRED.includes(key) && errorHandleMap[key]()
      })
    }
  }
}
const createQueryZustand = <
  Data = any,
  Variables extends AnyVariables = AnyVariables
>(
  document: DocumentInput<Data, Variables>
) => {
  return create<{
    data?: Data | null
    expiredError?: boolean
    fetch: (clientGraphQL: Client, variable?: Variables) => void
    refresh: (clientGraphQL: Client, variable?: Variables) => void
  }>((set, get) => {
    const fetchData = debounce(
      (
        clientGraphQL: Client,
        variable: Variables,
        errorHandleMap: ErrorHandleMap
      ) => {
        set((state) => ({ ...state, loading: true }))
        clientGraphQL
          .query(document, variable)
          .toPromise()
          .then((data) => {
            if (data.error) {
              catchExt(data.error.graphQLErrors, {
                ['EXPIRED_TENANT_PLAN_ERROR']: () => {
                  set((state) => ({ ...state, expiredError: true, data: null }))
                }
              })
            } else {
              set((state) => ({ ...state, data: data.data, loading: false }))
            }
          })
      }
    )
    return {
      fetch: (clientGraphQL: Client, variable?: Variables) =>
        !get().data && fetchData(clientGraphQL, variable),
      refresh: debounce(fetchData, 500)
    }
  })
}

const useSubcriptionData = createQueryZustand(QueryTenantPlan)
const useLocalCache = <T extends object>(
  data: T | undefined | null,
  key: string
) => {
  const getCacheData = (): { data?: T } =>
    !isServer
      ? JSON.parse(localStorage.getItem(`_cache_${key}`) || '{}')
      : undefined
  const [localCache, setLocalCache] = useState(
    getCacheData().data as T | undefined | null
  )
  useEffect(() => {
    if (data || data === null) {
      localStorage.setItem(
        `_cache_${key}`,
        JSON.stringify({
          meta: { expiredOn: addMinutes(new Date(), 5) },
          data: data
        })
      )
      setLocalCache(data)
    }
  }, [data])
  return localCache
}
const PAGES_NOT_FETCH_API_4_SUBSCRIPTIONS = [
  pathConfiguration?.authError,
  pathConfiguration?.careers?.list,
  pathConfiguration?.contactUs,
  pathConfiguration?.error404,
  pathConfiguration?.error500,
  pathConfiguration?.errorAccessDenied,
  pathConfiguration?.extension?.list,
  pathConfiguration?.login,
  pathConfiguration?.termsOfUse,
  pathConfiguration?.register,
  pathConfiguration?.onboarding,
  pathConfiguration?.verifyEmail,
  pathConfiguration?.tenantBlocked,
  pathConfiguration?.privacyPolicy,
  pathConfiguration?.selectCompany
  // pathConfiguration?.careerHub.main
]
export const refetchSubscriptionData = useSubcriptionData.getState().refresh
const useSubscriptionPlan = () => {
  const router = useRouter()
  const {
    data: subscriptionData,
    fetch,
    refresh,
    expiredError
  } = useSubcriptionData()

  const subscriptionDataCache = useLocalCache(
    subscriptionData,
    'subscriptionDataCache'
  )
  const data = useMemo(
    () =>
      subscriptionData?.tenantPlanShow && {
        isBlock: false,
        isTrial:
          subscriptionData?.tenantPlanShow?.status ===
          TENANT_PLANS_STATUS.trial,
        isExpired:
          subscriptionData?.tenantPlanShow?.tenantPlan &&
          subscriptionData?.tenantPlanShow?.status ===
            TENANT_PLANS_STATUS.expired,
        // subscriptionData?.tenantPlanShow?.tenantPlan &&
        // subscriptionData?.tenantPlanShow?.remainingTrialDays <= 0,
        daysLeft:
          subscriptionData?.tenantPlanShow?.remainingTrialDays > 0
            ? subscriptionData?.tenantPlanShow?.remainingTrialDays
            : 0,
        defaultPlan: subscriptionData?.tenantPlanShow?.tenantPlan?.defaultTrial,
        planFeatures: subscriptionData?.tenantPlanShow?.tenantPlan?.planFeatures
      },
    [subscriptionData]
  )
  const { clientGraphQL } = useContextGraphQL()
  const { user } = useBoundStore()
  const client = useGraphQLRequest({
    language: user?.language,
    isCareerHub:
      typeof window !== 'undefined' &&
      window.location.pathname.search('/careerhub/') > -1
  })

  useEffect(() => {
    const NOT_FETCH_CHUB_DYNAMIC_URL = [
      `${pathConfiguration.careerHub.login(router?.query?.slug as string)}`,
      `${pathConfiguration.careerHub.register(router?.query?.slug as string)}`,
      `${pathConfiguration.careerHub.jobs(router?.query?.slug as string)}`
    ]

    const isCustomDomain =
      NODE_ENV === 'development'
        ? !['localhost:3000'].includes(window.location.host)
        : !PUBLIC_APP_URL?.includes(window.location.host)

    const isCallApi = () =>
      !PAGES_NOT_FETCH_API_4_SUBSCRIPTIONS.some((url) =>
        router?.pathname.startsWith(url)
      ) &&
      !NOT_FETCH_CHUB_DYNAMIC_URL.some((url) =>
        window.location.pathname.startsWith(url)
      ) &&
      !isCustomDomain

    isCallApi() && fetch(clientGraphQL.query ? clientGraphQL : client, {})

    const onfocus = () => {
      if (isCallApi()) {
        refresh(clientGraphQL.query ? clientGraphQL : client, {})
      }
    }

    Router.events.on('routeChangeStart', onfocus)
  }, [])

  return {
    data,
    expiredError,
    subscriptionDataCache,
    planName: useMemo(
      () => subscriptionData?.tenantPlanShow?.tenantPlan?.name,
      [data]
    ),
    isLimitReached: useMemo(
      () =>
        subscriptionDataCache?.tenantPlanShow?.planLimitExceeded?.candidate ||
        subscriptionDataCache?.tenantPlanShow?.planLimitExceeded?.job ||
        subscriptionDataCache?.tenantPlanShow?.planLimitExceeded?.tenant_member,
      [subscriptionDataCache]
    ),
    isFeaturePremiumShow: (feature: FeatureName | string) => {
      const planFeature =
        subscriptionData?.tenantPlanShow?.tenantPlan?.planFeatures?.find(
          (f) => f.key === feature
        )
      return planFeature && !planFeature.enabled
    },
    isFeatureEnabled: (feature: FeatureName | string) => {
      const planFeature =
        subscriptionData?.tenantPlanShow?.tenantPlan?.planFeatures?.find(
          (f) => f.key === feature
        )
      return planFeature && !!planFeature?.enabled
    },
    isUnLockFeature: (feature: FeatureName | string) => {
      const planFeature =
        subscriptionData?.tenantPlanShow?.tenantPlan?.planFeatures?.find(
          (f) => f.key === feature
        )
      return planFeature && !!planFeature?.unlocked
    },
    showIconFeatureFromAdminSetting: (
      feature: FeatureName | string,
      isOwner: boolean
    ) => {
      const planFeature =
        subscriptionData?.tenantPlanShow?.tenantPlan?.planFeatures?.find(
          (f) => f.key === feature
        )
      const isUnLock = planFeature && !!planFeature?.unlocked
      return isUnLock || isOwner
    }
  }
}
export default useSubscriptionPlan
