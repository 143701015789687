import { StateCreator } from 'zustand'
import { ToastSlice } from './toast-slice'

export interface BulkActionSlice {
  bulkValues?: string[]
  bulkSelectedAll?: boolean
  bulkValuesKanban?: string[]
  bulkValuesKanbanUpdated?: string[]
  setBulkValues: (val: string[]) => void
  setBulkSelectedAll: (val: boolean) => void
  setBulkValuesKanban: (val: string[]) => void
  setBulkValuesKanbanUpdated: (val: string[]) => void
  resetBulkValues: () => void
  resetBulkValuesKanban: () => void
}

export const createBulkActionSlice: StateCreator<
  ToastSlice & BulkActionSlice,
  [],
  [],
  BulkActionSlice
> = (set: Function) => ({
  bulkValues: [],
  bulkSelectedAll: false,
  bulkValuesKanban: [],
  bulkValuesKanbanUpdated: [],
  setBulkValues: (val: string[]) => set({ bulkValues: val }),
  setBulkSelectedAll: (val: boolean) => set({ bulkSelectedAll: val }),
  setBulkValuesKanban: (val: string[]) => set({ bulkValuesKanban: val }),
  setBulkValuesKanbanUpdated: (val: string[]) =>
    set({ bulkValuesKanbanUpdated: val }),
  resetBulkValues: () => set({ bulkValues: [], bulkSelectedAll: false }),
  resetBulkValuesKanban: () =>
    set({ bulkValuesKanban: [], bulkValuesKanbanUpdated: [] })
})
