import { CUSTOM_DOMAIN_CAREER_LIST_URL } from '~/core/constants/url'
import {
  AGENCY_API,
  AGENCY_INVITATIONS_CONFIRM_API,
  CALENDAR_FEED_ICS_API,
  CHUB_API,
  CHUB_SIGN_OUT_API,
  DOWNLOAD_FILE_API,
  GRAPHQL_API,
  INVITATIONS_CHUB_CONFIRM_API,
  INVITATIONS_CONFIRM_API,
  LOGIN_API,
  LOGIN_CHUB_API,
  LOGIN_SSO_API,
  LOGOUT_API,
  PROFILES_EXPORT_CSV_API,
  REPORT_EXPORT_CANDIDATE_SOURCE,
  REPORT_EXPORT_REFERRAL,
  REPORT_EXPORT_TEAM_PRODUCTIVITY,
  REPORT_EXPORT_TIME_TO_HIRE,
  SIGN_OUT_API,
  VERIFY_RECAPTCHA_API
} from '../core/constants/api'
import { PUBLIC_API_URL, PUBLIC_ENABLE_API_PROXY } from '../core/constants/env'

const apiConfiguration = {
  domain: PUBLIC_API_URL,
  login: `${PUBLIC_API_URL}${LOGIN_API}`,
  CHUBlogin: `${PUBLIC_API_URL}${LOGIN_CHUB_API}`,
  loginSSO: `${PUBLIC_API_URL}${LOGIN_SSO_API}`,
  logout: `${PUBLIC_API_URL}${LOGOUT_API}`,
  logoutCHUB: `${PUBLIC_API_URL}${CHUB_SIGN_OUT_API}`,
  invitationsConfirm: `${PUBLIC_API_URL}${INVITATIONS_CONFIRM_API}`,
  invitationsCHUBConfirm: `${PUBLIC_API_URL}${INVITATIONS_CHUB_CONFIRM_API}`,
  signOut: SIGN_OUT_API,
  verifyReCaptcha: VERIFY_RECAPTCHA_API,
  downloadFile: `${PUBLIC_API_URL}${DOWNLOAD_FILE_API}`,
  graphQL: `${PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL}${GRAPHQL_API}`,
  agencyGraphQL: `${
    PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL
  }${AGENCY_API}${GRAPHQL_API}`,
  chubGraphQL: `${
    PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL
  }${CHUB_API}${GRAPHQL_API}`,
  customDomain: {
    graphQL: `${
      PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL
    }${CUSTOM_DOMAIN_CAREER_LIST_URL}${GRAPHQL_API}`,
    agencyGraphQL: `${
      PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL
    }${AGENCY_API}${CUSTOM_DOMAIN_CAREER_LIST_URL}${GRAPHQL_API}`,
    chubGraphQL: `${
      PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL
    }${CHUB_API}${CUSTOM_DOMAIN_CAREER_LIST_URL}${GRAPHQL_API}`
  },
  agencyInvitationsConfirm: `${PUBLIC_API_URL}${AGENCY_INVITATIONS_CONFIRM_API}`,
  profilesExportCsv: `${PUBLIC_API_URL}${PROFILES_EXPORT_CSV_API}`,
  reportExportTeamProductivityCsv: (prefix: string) =>
    `${PUBLIC_API_URL}${prefix}${REPORT_EXPORT_TEAM_PRODUCTIVITY}`,
  reportExportTimeToHireCsv: (prefix: string) =>
    `${PUBLIC_API_URL}${prefix}${REPORT_EXPORT_TIME_TO_HIRE}`,
  reportExportReferralCsv: (prefix: string) =>
    `${PUBLIC_API_URL}${prefix}${REPORT_EXPORT_REFERRAL}`,
  reportExportCandidateSourceCsv: (prefix: string) =>
    `${PUBLIC_API_URL}${prefix}${REPORT_EXPORT_CANDIDATE_SOURCE}`,
  calendarFeed: `${PUBLIC_API_URL}${CALENDAR_FEED_ICS_API}`
}

export default apiConfiguration
